import React from "react";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const TermsOfUse = () => (
  <div>
    <Navbar />
    <section className="privacy-main">
      <div className="container">
        <div className="flex-row">
          <div className="col col-10 offset-1">
            <h1>Terms of Use</h1>
            <p>Updated September 15, 2022</p>
            <p>
              Powerplay, welcomes you to our website (the "Site") and the
              applications and services available from us, through the Site, on
              your mobile device, or other platforms (collectively with the
              Site, the "Services"). Your use of the Services are governed by
              these Terms of Use (these "Terms"). Any time you use the Services
              in any way, you agree to be bound by these Terms. If you don't
              agree to these Terms, do not use the Services.
            </p>
            <p>
              Your use of the Services is also subject to our Privacy Policy,
              which you can access through the Services or on our website, as
              well as any policies and procedures we publish from time to time
              (collectively, the "Policies"). We reserve the right to modify
              these Terms at any time, with such changes becoming effective when
              we post the modified Terms to the Services. We also reserve the
              right to make any changes to the Services in any manner and to
              deny or terminate your access to the Services, even if you have an
              Account, in our sole discretion.
            </p>
            <p>
              Each time you use the Services, the then-current version of the
              Terms will apply. If you use the Services after a modification of
              these Terms, you agree to be bound by the Terms as modified.
            </p>
            <p>
              These Terms contain important information regarding your rights
              with respect to the Services, including your relationship with us,
              and include an arbitration provision that may limit your ability
              to pursue claims against us in court. Please read them carefully
              and review them regularly.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Eligibility</h2>
            <p>
              When you use the Services, you represent that you are (i) at least
              the age of majority in the jurisdiction where you reside or (ii)
              if you have not reached the age of majority in the jurisdiction
              where you reside, that you have received permission to use the
              Services from your parent or legal guardian.
            </p>
            <p>
              You represent that any information you submit to us when using the
              Services is accurate, truthful, and current. You also represent
              that your use of the Services does not violate any applicable law
              or regulation.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Registration</h2>
            <p>
              In order to use the Services, you will be required to register for
              an account (<strong>"Account"</strong>). As the person who
              registered for the Account (the <strong>"Account Owner"</strong>),
              you are entitled to certain administrative permissions as set out
              in the Services. You are responsible for the confidentiality and
              use of your Login Information and agree not to transfer or
              disclose your Login Information to any third party other than an
              individual with express authority to act on your behalf. You are
              solely responsible for any activities occurring under your
              Account. If you suspect any unauthorized use of your Account, you
              agree to notify us immediately. We reserve the right to
              discontinue offering the Services, including by terminating your
              Account, at any time pursuant to these Terms. You have no
              ownership right to your Account, and our liability if you are
              unable to access the Services, if any, is limited by these Terms.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Accounts</h2>
            <p>
              Accounts come in two primary groups: 1) Organizational Accounts,
              and 2) Individual Accounts. Organizational Accounts can be linked
              to a certain number of Individual Accounts as set out in your
              access plan.
            </p>
            <h3>Organizational Accounts</h3>
            <p>
              As the Account Owner of an Account opened on behalf of an
              organization (an <strong>"Organizational Account"</strong>), you
              may grant access to the Services to certain individuals (
              <strong>"Authorized Users"</strong>), subject to the limits of any
              plan for which you enroll. When registering for an Organizational
              Account, administering such Account, and when accessing or
              otherwise using the Services, you represent or warrant that the
              information you enter for your organization is correct. You
              further acknowledge and agree that the Organizational Account
              Owner is responsible for all activity conducted by all Authorized
              Users. Each Authorized User must have their own Login Information
              and use of a single individual Account by several end users shall
              be considered a material breach of this Agreement, and Powerplay
              reserves the right to terminate the Organizational Account for
              cause due to such breach.
            </p>
            <h3>Individual Accounts</h3>
            <p>
              As the Account Owner of an individual Account, you are responsible
              for your own actions. If you choose to add any users, you will
              become an Organizational Account for purposes of these Terms. If
              you have accepted an invitation to join the Services by your
              organization, and your Account becomes linked to such
              Organizational Account, you acknowledge that the administrators of
              the Organizational Account to which you are linked may have access
              to all activity/data logged or generated in your Account. You
              further acknowledge that you have no individual rights in your
              Account if you are linked below an Organizational Account, and
              that the Organizational Account's Account Owner may revoke your
              permission to access your Account or any Organizational Content as
              such Account Owner sees fit. Finally, you acknowledge that any and
              all Content you upload or otherwise supply to the Services shall
              become the property of your Organizational Account Owner
              immediately upon its acceptance by the Services, and you hereby
              assign all right, title, or interest in such Content to such
              Account Owner.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Payment Terms</h2>
            <p>
              In the event you have a paid account, fees are non-refundable
              except as required by law or in our sole discretion. If we
              terminate your Account without cause, we may refund you the fees
              for the unused portion of your subscription. If you sign up for
              our annual or monthly prepaid plans, and cancel those plans later,
              you are not entitled to a refund for the unused portion of your
              subscription period. You agree to pay all applicable fees when due
              and, if such fees are being paid via credit card or other
              electronic means, you authorize us to charge such fees using your
              selected payment method. 
              <strong>
                By default, customer accounts are set to auto-renew and we may
                automatically charge you for such renewal on or after the
                renewal date associated with your account unless you have
                cancelled the Service prior to its renewal date
              </strong>
              . We may revise fee rates for the Service from time to time and
              inform you before charging the revised amount. You are responsible
              for providing complete and accurate billing information to
              Powerplay. We may suspend or terminate your use of the Service if
              fees become past due. You are responsible for all taxes (excluding
              taxes on our net income), and we will charge tax if required to do
              so by law.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Permitted Uses/License</h2>
            <p>
              You are authorized to access the Site and our application for the
              sole purpose of viewing and using the Services on your computer or
              device. We authorize you to copy materials from the Services to
              your device's local or cloud storage solely for the purpose of
              viewing and using the Services. You may not use the Service for
              any illegal or unauthorized purpose. You agree to comply with all
              laws, rules, and regulations (for example, federal, state, local
              and provincial) applicable to your use of the Service and your
              User Content (defined below), including but not limited to,
              copyright laws.
            </p>
            <p>
              You may not decompile, disassemble, rent, lease, loan, sell,
              sublicense, or create derivative works from the Site, the
              Services, or any data thereon. You may not use any robot, spider,
              or other automatic device or manual process to monitor or copy the
              Site or its content without our prior written permission. Your
              failure to abide by these conditions will immediately terminate
              your right to access the Site or to use the Services and may give
              rise to legal action related to the protection of our intellectual
              property rights or the intellectual property rights of third
              parties.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Location-based Services</h2>
            <p>
              Some of the Services may require that location functionality be
              enabled on the relevant device in order to work properly. You
              acknowledge and agree that if location permissions and
              functionalities are not enabled on the device with which you
              access the Services, the Services may not work appropriately or at
              all. We will use any location information we receive from you in
              accordance with our Privacy Policy.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Third Party Sites</h2>
            <p>
              The Site may contain links to third party applications or websites
              we do not operate, control, or maintain (
              <strong>"Third Party Sites"</strong>). We do not endorse any Third
              Party Sites, and we make no representation or warranty in any
              respect regarding the Third Party Websites. Any links to Third
              Party Sites on the Services are provided solely for your
              convenience. If you do access any Third Party Sites, you do so at
              your own risk and waive any and all claims against us regarding
              the Third Party Sites or our links thereto.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Data</h2>
            <p>
              You agree that we have the right to collect and analyze data and
              other information relating to the provision, use and performance
              of various aspects of the Services, and related systems (for
              example, anonymous and aggregated information concerning user
              behavior and use of the Services), and we will be free (during and
              after the term hereof) to (i) use such information and data to
              improve and enhance the Site Services and for other development,
              diagnostic and corrective purposes in connection with the Site and
              Services and other of our offerings, and (ii) disclose such data
              solely in aggregate or other de-identified form in connection with
              its business.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Ownership</h2>
            <p>
              The Powerplay Services belong in their entirety to us. We grant
              only that limited license herein to you. User Content (as defined
              below) belongs to you, and we take no ownership interest in it.
              The Powerplay name and logo are our trademarks, and may not be
              copied, imitated or used, in whole or in part, without our prior
              written permission. In addition, all page headers, custom
              graphics, button icons, and scripts are our service marks,
              trademarks, and/or trade dress, and may not be copied, imitated or
              used, in whole or in part, without prior written permission from
              us.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>User Conduct</h2>
            <p>
              You agree not to use any part of the Services to take any action
              or actions that (including with respect to any User Content): (i)
              are patently offensive in any manner (as determined in our sole
              discretion), (ii) involve commercial activities without our prior
              written consent, such as contests or sweepstakes, (iii) are
              contrary to our public image, goodwill, or reputation, (iv)
              infringe on our or any third party's intellectual property rights,
              (v) violate any law or any third party's legal rights, or (vi)
              "frame" or "mirror" any part of the Site without our prior written
              consent.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>User Content Generally</h2>
            <p>
              When you post content and information to the Site or in connection
              with the Services ("Content"), including photos or other
              information about the projects you are undertaking, you represent
              and warrant to us that (i) you own or have the necessary rights to
              use and share the Content, (ii) the posting of the Content does
              not violate any rights of any person or entity, and (iii) you have
              no agreement with or obligations to any third party that would
              prohibit your use of the Site or Services in the manner so used.
              You agree to pay all royalties, fees, and any other monies owing
              to any person or entity by reason of any Content posted by you to
              the Site or through the Services. You acknowledge and agree that
              we may, in our sole discretion, remove Content at any time and for
              any reason, or for no reason at all. If you are an Authorized User
              of an Organizational Account, you also acknowledge and agree that
              any Content uploaded to the Services shall immediately become the
              property of such Organizational Account, and you assign all right,
              title, and interest in such Content to the Organizational Account
              Owner upon posting the Content to the Services.
            </p>
            <p>
              <strong>
                You agree not to include any personally-identifiable information
                about yourself or any other person in any User Content except as
                requested or required by Powerplay.
              </strong>
            </p>
            <p>
              By posting or storing any Content in the Services, you give us and
              our affiliates a perpetual, nonexclusive, irrevocable,
              royalty-free, sublicensable and transferable worldwide license to
              all intellectual property rights you own or control to use,
              transmit, reproduce, commercialize, distribute, modify, create
              derivative works from, and otherwise exploit such Content for any
              and all purposes and without further notice to you, attribution,
              and without the requirement of any permission or payment to you or
              any other person or entity, except as otherwise expressly provided
              herein. You also authorize and appoint us as your attorney in fact
              and agent with full power to enter into and execute any document
              or undertake any action we may consider appropriate to use or
              enforce the grant of rights and waivers set forth in these Terms.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Feedback</h2>
            <p>
              From time to time, you may be asked to provide feedback on the
              Services or the Platform, whether by a survey or by giving a
              written testimonial (<strong>"Feedback"</strong>). Feedback shall
              include any communications directed to us related to the Services,
              including without limitation suggestions for new features or
              functionality or comments, questions, or other suggestions. If you
              choose to give such Feedback, you agree that all such Feedback
              shall belong entirely to us, including any ideas, know-how,
              concepts, techniques, or other intellectual property rights
              contained in such Feedback, and you hereby assign all right,
              title, and interest in such Feedback to us. We shall be free to
              use any Feedback, with or without attribution (subject to our
              obligations to protect your privacy) or compensation to the
              provider.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Content-Sharing Platform</h2>
            <p>
              The Services include a content-sharing platform (the{" "}
              <strong>"Platform"</strong>) whereby content-creating users (
              <strong>"Creators"</strong>) can create and share certain Content
              with other users subject to the sharing permission set by the
              Creator in the Platform. By sharing Content, you represent and
              warrant that you have all necessary permission to share the
              Content in its form. If the Content is a photograph, you represent
              that all personally identifiable information has been removed from
              the photograph. At times, entering project information into the
              Services and Platform may require sharing the personal information
              of third parties, such as clients. You agree that only such
              personal information as is necessary has been uploaded to the
              Services and Platform, and that you have permission from the
              subject to share their information with whoever has permission to
              see such personal information inside the Services.
            </p>
            <p>
              Except as otherwise described in the Service's Privacy Policy, as
              between you and us, any Content will be non-confidential and
              nonproprietary and we will not be liable for any use or disclosure
              of User Content.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Losses of Stored Content</h2>
            <p>
              Although it is our intention for the Service to be available as
              much as possible, there will be occasions when the Service may be
              interrupted, including, without limitation, for scheduled
              maintenance or upgrades, for emergency repairs, for unscheduled
              downtime, for system and server failures, or due to failure of
              telecommunications links and/or equipment. Consequently, we
              encourage you to maintain your own backup of your Content. In
              other words, we are not a backup service and you agree that you
              will not rely on the Service for the purposes of Content backup or
              storage. We will not be liable to you for any modification,
              suspension, or discontinuation of the Services, or the loss of any
              Content. You also acknowledge that the Internet may be subject to
              breaches of security and that the submission of Content or other
              information may not be secure.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Copyright Infringement</h2>
            <p>
              We respect the intellectual property rights of others. If you
              believe that your work has been improperly copied and posted,
              please provide us with the following information: (i) name,
              address, telephone number, email address and an electronic or
              physical signature of the copyright owner or of the person
              authorized to act on his/her behalf; (ii) a description of the
              copyrighted work that you claim has been infringed; (iii) a
              description of where on the Site the material that you claim is
              infringing is located; (iv) a written statement that you have a
              good faith belief that the disputed use is not authorized by the
              copyright owner, its agent, or the law; and (v) a statement by
              you, made under penalty of perjury, that the above information in
              your notice is accurate and that you are the copyright owner or
              authorized to act on the copyright owner's behalf. These
              requirements must be followed to give us legally sufficient notice
              of infringement. Send copyright infringement complaints to the
              following email address:{" "}
              <a href="mailto:help@getpowerplay.in">help@getpowerplay.in</a>. We
              suggest that you consult your legal advisor before filing a notice
              with our copyright agent. There can be penalties for false claims.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Warranty Disclaimer</h2>
            <p>
              You agree that the Services are available on an "as is" basis,
              without any warranty, and that you use the Services at your own
              risk. We disclaim, to the maximum extent permitted by law, any and
              all warranties, whether express or implied, including, without
              limitation, (a) warranties of merchantability or fitness for a
              particular purpose, (b) warranties against infringement of any
              third party intellectual property or proprietary rights, ©
              warranties relating to delays, interruptions, errors, or omissions
              in the Services or on the Site, (d) warranties relating to the
              accuracy or correctness of data on the Services, and (e) any other
              warranties otherwise relating to our performance, nonperformance,
              or other acts or omissions.
            </p>
            <p>
              We do not warrant that the Site or the Services will operate
              error-free or that they are free of computer viruses and/or other
              harmful materials. If your use of the Site or the Services results
              in the need for servicing or replacing equipment or data, we are
              not responsible for any such costs.
            </p>
            <p>
              Some jurisdictions do not allow the exclusion or limitation of
              certain categories of damages or implied warranties; therefore,
              the above limitations may not apply to you. In such jurisdictions,
              our liability is limited to the greatest extent permitted by law.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Limitation of Liability</h2>
            <p>
              Any liability we have to you in connection with these Terms, under
              any cause of action or theory, is strictly limited to, in
              aggregate for all violations, $100. Without limiting the previous
              sentence, in no event shall we or any of our affiliates be liable
              to you for any indirect, special, incidental, consequential,
              punitive, or exemplary damages arising out of or in connection
              with, these Terms. The foregoing limitations apply whether the
              alleged liability is based on contract, tort, negligence, strict
              liability, or any other basis, even if we or our affiliates have
              been advised of the possibility of such damages.
            </p>
            <p>
              You agree to indemnify and hold us harmless for any breach of
              security or any compromise of your Account.
            </p>
            <p>
              Some jurisdictions do not allow the exclusion or limitation of
              incidental or consequential; therefore, the above limitations may
              not apply to you. In such jurisdictions, our liability is limited
              to the greatest extent permitted by law.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Indemnification</h2>
            <p>
              You agree to indemnify and hold harmless us, our affiliates and
              our and their officers, directors, partners, agents, and employees
              from and against any loss, liability, claim, or demand, including
              reasonable attorneys' fees (collectively,{" "}
              <strong>"Claims"</strong>), made by any third party due to or
              arising out of your use of the Services in violation of these
              Terms, any breach of the representations and warranties you make
              in these Terms, or your User Content. You agree to be solely
              responsible for defending any Claims against or suffered by us
              with counsel subject to our reasonable approval and further
              subject to our right to participate with counsel of our own
              choosing.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Electronic Signatures and Notices</h2>
            <p>
              Certain activities on the Services may require you to make an
              electronic signature. You understand and accept that an electronic
              signature has same legal rights and obligations as a physical
              signature.
            </p>
            <p>
              If you have an Account, you agree that we may provide you any and
              all required notices electronically through your Account or other
              electronic means. You agree that we are not responsible for any
              delivery fees charged to you as a result of your receipt of our
              electronic notices.
            </p>
          </div>
          <div className="col col-10 offset-1">
            <h2>Miscellaneous</h2>
            <p>
              These Terms are governed by Nebraska law, without giving effect to
              conflicts of law principles. You agree that, to the extent
              applicable and expressly subject to the dispute resolution
              provisions below, to submit to the exclusive jurisdiction of the
              state and federal courts located in Lancaster County, Nebraska in
              circumstances where these Terms permit litigation in court. We may
              assign, transfer, delegate, or otherwise hypothecate our rights
              under these Terms in our sole discretion. If we fail to enforce a
              provision of these Terms, you agree that such a failure does not
              constitute a waiver to enforce the provision (or any other
              provision hereunder). If any provision of these Terms is held or
              made invalid, the invalidity does not affect the remainder of
              these Terms. We reserve all rights not expressly granted in these
              Terms and disclaim all implied licenses.
            </p>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
);

export default TermsOfUse;
